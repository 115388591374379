.highlightedTitleAndSubtitleHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 1.5rem 0;
}

.highlightedTitleAndSubtitleH1 {
    color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    font-size: 3.5em;
}

.highlightedTitleAndSubtitleH3 {
    color: black;
    font-weight: 600;
    font-size: 1.5em;
    font-family: "Cinzel", serif;
}

.isDeadWing {
    font-size: 1.5em;
    padding-inline: 0.2rem;
}

.isDeadWingLeft {
    font-size: 1.5em;
    padding-inline: 0.2rem;
    transform: scale(-1, 1);
}

.isDead {
    color: black;
    font-weight: 600;
    font-size: 1.5em;
    font-family: "Cinzel", serif;
}

.subtitlePair {
    display: flex;
}

.h3ExtraWord {
    color: black;
    font-weight: 600;
    font-size: 1.5em;
    font-family: "Cinzel", serif;
    padding-inline: 0.4rem;
}

@media (max-width: 768px) {
    .highlightedTitleAndSubtitleH1 {
        font-size: 2.5em;
    }
    
    .highlightedTitleAndSubtitleH3 {
        font-size: 1.4em;
    }
    
    .isDeadWing {
        font-size: 1.4em;
    }
    
    .isDeadWingLeft {
        font-size: 1.4em;
    }
    
    .isDead {
        font-size: 1.4em;
    }
    
    .h3ExtraWord {
        font-size: 1.4em;
    }
}