.countdownElementHolder {
    display: flex;
    width: 170px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-inline: 1rem;
    line-height: 0;
    font-size: large;
    font-weight: 600;
    border-radius: 10px;
    border-width: 10px;
    background-color: rgba(41, 41, 41, 0.5);
    border: 1px rgba(41, 41, 41, 0.5);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.value {
    color: white;
    font-size: 2.5rem;
    font-family: "Cinzel", serif;
}

.label {
  padding: 1rem; /* Keep the padding for horizontal spacing */
  color: rgb(227, 201, 152);
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.7em;
  margin-top: 0.5rem; /* Reduce top margin */
  margin-bottom: 0.2rem; /* Reduce bottom margin (optional) */
}

@media (max-width: 768px) {
    .countdownElementHolder {
        font-size: 1rem;
        width: 120px;
    }
    
    .value {
        font-size: 1.9rem;
    }
}