.imgAndTextHolder{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-inline: 1rem;
    margin: 0;
}

.iconWithTextFirstText {
    line-height: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.iconWithTextIcon {
    height: 1rem;
    width: 1rem;
    padding-inline-end: 0.3rem;
}

.textHolder {
    padding-inline: 1rem;
    line-height: 0;
}



.iconWithTextHolder{
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: start;
}