body {
  background-color: #E8E8E8;
  font-family: 'Noto Serif Kannada', serif;
}

.section1 {
  height: 100vh;
  background-image: url("/public/assets/section1_background1.jpg");
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.section1Content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.section1Title {
  color: white;
  font-weight: bolder;
  font-size: 2.5em;
  margin-inline: 0.5rem;
}

.section1Subtitle {
  color: white;
  font-weight: bolder;
  font-size: 1.5em;
  margin-inline: 0.5rem;
}

.bouncing-hearth {
  animation: bounce 3.5s infinite alternate cubic-bezier(0.4, 0, 0.6, 1);
  height: 30px;
  padding-bottom: 4rem;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }

  s 100% {
    transform: translateY(0);
  }
}

.section2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 2rem 0;
}

.section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 2rem 0;
}

.section3MiddleIcon {
  height: 100px;
  margin: 1rem;
}

.section3MiddleIconRight {
  height: 100px;
  margin: 1rem;
  transform:scale(-1,1);
}

.leavesHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.section5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 2rem 0;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0 3rem 0;
}

.confirmationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-radius: 1rem;
  background-color: white;
  padding: 2rem;
}

.formTitle {
  color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    font-size: 3.5em;
}

.formSubtitle {
  color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    font-size: 3.5em;
}

.form2ndSubtitle {
  color: #404040;
    font-weight: 500;
    font-size: 1.3em;
    margin-bottom: 0;
    text-align: center;
}

.confirmationFormItem {
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
  width: 60%;
  justify-content: space-between;
  font-weight: 600;
}

.intoleranceHolder {
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
}

.confirmationLabel {
  width: 100%;
}

.confirmationInput {
  width: 100%;
  height: 1.8rem;
  font-size: 16px;
  box-sizing: border-box;
}

.errorLabel {
  background-color: rgb(247, 174, 174);
  border-radius: 5px;
  border-color: rgb(149, 40, 40);
  border-width: 1px;
  border-style: solid;
  width: 100%;
  margin-top: 1rem;
}

.errorLabel p{
  padding-inline: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(149, 40, 40);
  text-align: center;
}

.confirmationButton {
  width: 60%;
  margin-top: 2rem;
  background-color: rgba(50, 158, 109, 0.887);
  color: white;
  height: 2.3rem;
  border-radius: 0.2rem;
  border-color: rgba(47, 197, 129, 0.887);
  font-family: 'Noto Serif Kannada', serif;
  padding-top: 0.45rem;
  font-weight: 800;
  font-size: 0.9rem;
}

.phoneContactHolder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  background: white;
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-top: 2.2rem;
}

.phoneContactTitle {
  color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    font-size: 2.5em;
    padding-inline: 2rem;
    text-align: center;
    margin-bottom: 0;
}

.phoneContactPhone {
  text-align: center; /* Center the text horizontally */
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  
}

.menu-bar {
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
  overflow: auto;
}

@media (min-width: 769px) {
  .content {
    margin-left: 30%;
  }
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .section1Title {
    color: beige;
    font-weight: bolder;
    font-size: 2em;
    margin-inline: 0.5rem;
  }

  .section1Subtitle {
    color: beige;
    font-weight: bolder;
    font-size: 1.2em;
    margin-inline: 0.5rem;
  }


  .confirmationFormItem {
    width: 90%;
  }

  .confirmationForm {
    width: 80%;
    margin-inline: 1.5rem;
  }

  .phoneContactHolder{
    width: 90%;
  }

  .formSubtitle {
    font-size: 2.6rem;
  }

  .formTitle {
    font-size: 2.6rem;
  }
}