.headerCard {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border-radius: 2rem;
  background-color: white;
  margin: 2rem 1rem 2rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-overflow: ellipsis;
  width: 80%;
}

.headerCardTitle{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.headerTitleImage {
  height: 40px;
  padding-bottom: 15px;
  margin-inline: 10px;
}

.HeaderTitle {
  font-size: large;
  font-weight: 600;
  font-size: 2rem;
  font-family: "Cinzel", serif;
  padding-inline: 0.2rem;
  text-align: center;
}

.HeaderText {
  font-size: large;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Montserrat", sans-serif;
  padding-inline: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .headerCard {
    width: 90%;
    /* Width for screens wider than 768px */
    max-width: 40rem;
  }
}

@media (max-width: 768px) {
  .headerCard {
    width: 90%;
    /* Width for screens narrower than 480px */
  }
}