.countDownToDateHolder {
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 0;
    align-items: center;
    justify-content:center;
    margin: 2rem 0 2rem 0;
    min-height: 25rem;
}

.countdownElements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
}

.countDownToDateTitle{
    color: white;
    font-size: large;
    font-weight: 600;
    font-size: 2.5em;
    font-family: "Cinzel", serif;
    padding-inline: 0.2rem;
}

@media (max-width: 768px) {
    .countDownToDateTitle{
        font-size: 1.5em;
    }
}