/* src/MenuBar.css */

.menu-bar {
  width: 30%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f4f4f4;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}

.menu-bar-img {
  height: 3rem;
}

.menu-bar-title {
  color: #404040;
  font-family: "Alex Brush", cursive;
  font-weight: 800;
  font-size: 2.1em;
  margin-bottom: 0;
}

.menu-bar-subtitle {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  font-weight: 400;
}

.menu-bar.visible {
  transform: translateX(0);
}

.menu-bar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listItem {
  padding: 20px;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 0;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.listItem:hover {
  color: #245c90;
  text-decoration: underline;
  text-decoration-color: #245c90;
}

.menu-copyright {
  font-size: 0.7rem;
}

.toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  background: none;
  border: none;
}

.toggle-button img {
  width: 24px;
  /* Adjust the width and height as per your icon size */
  height: 24px;
  transition: transform 0.3s ease-in-out;
  /* Add a transition for smooth transform */
}

/* Rotate the close icon when visible */
.toggle-button img.close-icon {
  transform: rotate(180deg);
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .menu-bar {
    width: 100%;
  }

  .toggle-button {
    display: block;
  }
}

@media (min-width: 769px) {
  .toggle-button {
    display: none;
  }
}