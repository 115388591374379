.TextWithBackgroundAndBottomDateHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 2rem 0;
    margin: 3rem 0 3rem 0;
    width: 100%;
    background-size: cover;
}

.TextWithBackgroundAndBottomDateText {
    color: black;
    font-size: large;
    font-weight: 600;
    font-size: 2rem;
    font-family: "Cinzel", serif;
    padding-inline: 0.2rem;
    padding-inline: 2rem;
    text-align: center;
}

.TextWithBackgroundAndBottomDateImage {
    height: 30px;
}

.TextWithBackgroundAndBottomDateDate {
    color: black;
    font-size: large;
    font-weight: 600;
    font-size: 2em;
    font-family: "Cinzel", serif;
    padding-inline: 0.2rem;
    padding-inline: 2rem;
    text-align: center;
}