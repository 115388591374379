.locationDetailHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Cinzel", serif;
}

.locationDetailImage{
    width: 100%;
    height: 25rem;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
}

.locationSectionHolder {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.locationDetailSectionTitle {
    margin-inline: 1rem;
    line-height: 0;
}

.locationSection {
    width: 90%;
    margin-inline: 1.5rem;
    background-color: white;
    border-radius: 1rem;
    margin-top: 2rem;
    padding-bottom: 1em;
    color: #404040;
}

.locationDetailTitle {
    color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    font-size: 3.5em;
    line-height: 0;
}

.locationDetailSubtitle {
    color: #404040;
    font-family: "Alex Brush", cursive;
    font-weight: 400;
    line-height: 0;
    font-size: 3.5em;
}


  @media (max-width: 1224px) { /* Adjust breakpoint as needed */
    /* Styles for medium-sized screens */
    .locationSectionHolder {
        flex-direction: column;
      }
  }